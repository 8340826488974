<template>
    <div v-if="plans.length">
        <div class="flex justify-center gap-x-8">
            <PlanCard
                v-for="plan in plans"
                :key="plan.id"
                :data="plan"
            />
        </div>
    </div>
</template>
<script setup>
    import PlanCard from '@/components/workspace/subscription/PlanCard';
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    const plans = ref([]);
    onMounted(async () => {
        const { data: { data } } = await axios.get('profile/plans');
        plans.value = data;
    });
</script>