<template>
  <div class="flex justify-center gap-5">
    <div class="payment-form border border-secondary-five rounded i-p-30">
        <h2 class="mb-7 fs-16 font-semibold text-primary-one">Payment information</h2>
        <div class="flex gap-4 mb-5">
            <div id="card-element"> </div>
        </div>
        <div class="flex gap-4 mb-5">
            <InputText v-model="form.streetName" placeholder="Street name" class="w-1/2" />
            <InputText v-model="form.suite" placeholder="Apt./Suite (optional)" class="w-1/2" />
        </div>
        <div class="flex gap-4 mb-5">
            <CountrySelect v-model="form.country" @change="selectCountry" class="w-1/2" />
            <InputText v-model="form.city" placeholder="City" class="w-1/2" />
        </div>
        <div class="flex gap-4 mb-5">
            <StateSelect v-model="form.state" :states="states" class="w-1/2" />
            <InputText v-model="form.postalCode" placeholder="Zip code" class="w-1/2" />
        </div>
        <div class="flex gap-4">
            <InputText v-model="form.email" placeholder="Enter email address" class="w-full" />
        </div>
        <button
            class="btn-proceed border border-primary-four rounded inline-flex justify-center gap-3 items-center mt-7"
            @click="onPayment"  
            :disabled="loading"
        >
            <LoadingSpinner v-if="loading" class="w-6 h-6" />
            <span> {{ loading ? 'processing ...' : 'Proceed to Pay' }}</span>
        </button>
    </div>
    <PaymentSummary :plan="plan" :plan_id="plan_id"  />
  </div>
</template>


<script setup>
    import InputText from '@/components/workspace/subscription/InputText';
    import StateSelect from '@/components/workspace/subscription/StateSelect';
    import PaymentSummary from '@/components/workspace/subscription/PaymentSummary';
    import CountrySelect from '@/elements/CountrySelect';
    import useSubscription from '@/composable/useSubscription';
    import useDirectory from '@/composable/useDirectory'
    import store  from '@/store';
    import { ref, computed, onMounted } from 'vue';
    import router from "@/routes/routes.js";
    const route = router.currentRoute;

    const { processPayment, loadCardElement, loading, form, cardElement } = useSubscription();
    const { getStateByCountryCode, getCountryDetails } = useDirectory();
    const props = defineProps({
        plan_id: [Number, String],
        plan: {
            type: Object,
            default: () => Object(null)
        }
    });
    const states = ref([]);
    const workspace = computed(() => {
        let workspaces = store.getters['workspace/workspaces'];
        return workspaces.find(item => item.id == route.params.workspace_id)
    });
    onMounted( async () => {
        await loadCardElement();
        cardElement.value.mount('#card-element');
    });
    const onPayment = async () => {
       await processPayment(props.plan_id, workspace.value);
    }
    const selectCountry = async (code) => {
        form.countryCode = code;
        states.value = await getStateByCountryCode(code);
    }

</script>

<style scoped>
    .payment-form {
        width: 610px;
        height: 440px;
    }

    .btn-proceed {
        width: 166px;
        height: 40px;

    }
</style>