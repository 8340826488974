export const countries = [
    { "text": "Afghanistan", "value": "AF", "country-code": '93' },
    { "text": "Åland Islands", "value": "AX", "country-code": '' },
    { "text": "Albania", "value": "AL", "country-code": '355' },
    { "text": "Algeria", "value": "DZ", "country-code": '213' },
    { "text": "American Samoa", "value": "AS", "country-code": '1-684' },
    { "text": "Andorra", "value": "AD", "country-code": '376' },
    { "text": "Angola", "value": "AO", "country-code": '244' },
    { "text": "Anguilla", "value": "AI", "country-code": '1-264' },
    { "text": "Antarctica", "value": "AQ", "country-code": '672' },
    { "text": "Antigua and Barbuda", "value": "AG", "country-code": '1-268' },
    { "text": "Argentina", "value": "AR", "country-code": '54' },
    { "text": "Armenia", "value": "AM", "country-code": '374' },
    { "text": "Aruba", "value": "AW", "country-code": '297' },
    { "text": "Australia", "value": "AU", "country-code": '61' },
    { "text": "Austria", "value": "AT", "country-code": '43' },
    { "text": "Azerbaijan", "value": "AZ", "country-code": '994' },
    { "text": "Bahamas", "value": "BS", "country-code": '1-242' },
    { "text": "Bahrain", "value": "BH", "country-code": '973' },
    { "text": "Bangladesh", "value": "BD", "country-code": '880' },

    { "text": "Barbados", "value": "BB", "country-code": '' },
    { "text": "Belarus", "value": "BY", "country-code": '' },
    { "text": "Belgium", "value": "BE", "country-code": '' },
    { "text": "Belize", "value": "BZ", "country-code": '' },
    { "text": "Benin", "value": "BJ", "country-code": '' },
    { "text": "Bermuda", "value": "BM", "country-code": '' },
    { "text": "Bhutan", "value": "BT", "country-code": '' },
    { "text": "Bolivia", "value": "BO", "country-code": '' },
    { "text": "Bosnia and Herzegovina", "value": "BA", "country-code": '' },
    { "text": "Botswana", "value": "BW", "country-code": '' },
    { "text": "Bouvet Island", "value": "BV", "country-code": '' },
    { "text": "Brazil", "value": "BR", "country-code": '' },
    { "text": "British Indian Ocean Territory", "value": "IO", "country-code": '' },
    { "text": "Brunei Darussalam", "value": "BN", "country-code": '' },
    { "text": "Bulgaria", "value": "BG", "country-code": '' },
    { "text": "Burkina Faso", "value": "BF", "country-code": '' },
    { "text": "Burundi", "value": "BI", "country-code": '' },
    { "text": "Cambodia", "value": "KH", "country-code": '' },
    { "text": "Cameroon", "value": "CM", "country-code": '' },
    { "text": "Canada", "value": "CA", "country-code": '' },
    { "text": "Cape Verde", "value": "CV", "country-code": '' },
    { "text": "Cayman Islands", "value": "KY", "country-code": '' },
    { "text": "Central African Republic", "value": "CF", "country-code": '' },
    { "text": "Chad", "value": "TD", "country-code": '' },
    { "text": "Chile", "value": "CL", "country-code": '' },
    { "text": "China", "value": "CN", "country-code": '' },
    { "text": "Christmas Island", "value": "CX", "country-code": '' },
    { "text": "Cocos (Keeling) Islands", "value": "CC", "country-code": '' },
    { "text": "Colombia", "value": "CO", "country-code": '' },
    { "text": "Comoros", "value": "KM", "country-code": '' },
    { "text": "Congo", "value": "CG", "country-code": '' },
    { "text": "Congo, The Democratic Republic of the", "value": "CD", "country-code": '' },
    { "text": "Cook Islands", "value": "CK", "country-code": '' },
    { "text": "Costa Rica", "value": "CR", "country-code": '' },
    { "text": "Cote D'Ivoire", "value": "CI", "country-code": '' },
    { "text": "Croatia", "value": "HR", "country-code": '' },
    { "text": "Cuba", "value": "CU", "country-code": '' },
    { "text": "Cyprus", "value": "CY", "country-code": '' },
    { "text": "Czech Republic", "value": "CZ", "country-code": '' },
    { "text": "Denmark", "value": "DK", "country-code": '' },
    { "text": "Djibouti", "value": "DJ", "country-code": '' },
    { "text": "Dominica", "value": "DM", "country-code": '' },
    { "text": "Dominican Republic", "value": "DO", "country-code": '' },
    { "text": "Ecuador", "value": "EC", "country-code": '' },
    { "text": "Egypt", "value": "EG", "country-code": '' },
    { "text": "El Salvador", "value": "SV", "country-code": '' },
    { "text": "Equatorial Guinea", "value": "GQ", "country-code": '' },
    { "text": "Eritrea", "value": "ER", "country-code": '' },
    { "text": "Estonia", "value": "EE", "country-code": '' },
    { "text": "Ethiopia", "value": "ET", "country-code": '' },
    { "text": "Falkland Islands (Malvinas)", "value": "FK", "country-code": '' },
    { "text": "Faroe Islands", "value": "FO", "country-code": '' },
    { "text": "Fiji", "value": "FJ", "country-code": '' },
    { "text": "Finland", "value": "FI", "country-code": '' },
    { "text": "France", "value": "FR", "country-code": '' },
    { "text": "French Guiana", "value": "GF", "country-code": '' },
    { "text": "French Polynesia", "value": "PF", "country-code": '' },
    { "text": "French Southern Territories", "value": "TF", "country-code": '' },
    { "text": "Gabon", "value": "GA", "country-code": '' },
    { "text": "Gambia", "value": "GM", "country-code": '' },
    { "text": "Georgia", "value": "GE", "country-code": '' },
    { "text": "Germany", "value": "DE", "country-code": '' },
    { "text": "Ghana", "value": "GH", "country-code": '' },
    { "text": "Gibraltar", "value": "GI", "country-code": '' },
    { "text": "Greece", "value": "GR", "country-code": '' },
    { "text": "Greenland", "value": "GL", "country-code": '' },
    { "text": "Grenada", "value": "GD", "country-code": '' },
    { "text": "Guadeloupe", "value": "GP", "country-code": '' },
    { "text": "Guam", "value": "GU", "country-code": '' },
    { "text": "Guatemala", "value": "GT", "country-code": '' },
    { "text": "Guernsey", "value": "GG", "country-code": '' },
    { "text": "Guinea", "value": "GN", "country-code": '' },
    { "text": "Guinea-Bissau", "value": "GW", "country-code": '' },
    { "text": "Guyana", "value": "GY", "country-code": '' },
    { "text": "Haiti", "value": "HT", "country-code": '' },
    { "text": "Heard Island and Mcdonald Islands", "value": "HM", "country-code": '' },
    { "text": "Holy See (Vatican City State)", "value": "VA", "country-code": '' },
    { "text": "Honduras", "value": "HN", "country-code": '' },
    { "text": "Hong Kong", "value": "HK", "country-code": '' },
    { "text": "Hungary", "value": "HU", "country-code": '' },
    { "text": "Iceland", "value": "IS", "country-code": '' },
    { "text": "India", "value": "IN", "country-code": '' },
    { "text": "Indonesia", "value": "ID", "country-code": '' },
    { "text": "Iran, Islamic Republic Of", "value": "IR", "country-code": '' },
    { "text": "Iraq", "value": "IQ", "country-code": '' },
    { "text": "Ireland", "value": "IE", "country-code": '' },
    { "text": "Isle of Man", "value": "IM", "country-code": '' },
    { "text": "Israel", "value": "IL", "country-code": '' },
    { "text": "Italy", "value": "IT", "country-code": '' },
    { "text": "Jamaica", "value": "JM", "country-code": '' },
    { "text": "Japan", "value": "JP", "country-code": '' },
    { "text": "Jersey", "value": "JE", "country-code": '' },
    { "text": "Jordan", "value": "JO", "country-code": '' },
    { "text": "Kazakhstan", "value": "KZ", "country-code": '' },
    { "text": "Kenya", "value": "KE", "country-code": '' },
    { "text": "Kiribati", "value": "KI", "country-code": '' },
    { "text": "Korea, Democratic People'S Republic of", "value": "KP", "country-code": '' },
    { "text": "Korea, Republic of", "value": "KR", "country-code": '' },
    { "text": "Kuwait", "value": "KW", "country-code": '' },
    { "text": "Kyrgyzstan", "value": "KG", "country-code": '' },
    { "text": "Lao People'S Democratic Republic", "value": "LA", "country-code": '' },
    { "text": "Latvia", "value": "LV", "country-code": '' },
    { "text": "Lebanon", "value": "LB", "country-code": '' },
    { "text": "Lesotho", "value": "LS", "country-code": '' },
    { "text": "Liberia", "value": "LR", "country-code": '' },
    { "text": "Libyan Arab Jamahiriya", "value": "LY", "country-code": '' },
    { "text": "Liechtenstein", "value": "LI", "country-code": '' },
    { "text": "Lithuania", "value": "LT", "country-code": '' },
    { "text": "Luxembourg", "value": "LU", "country-code": '' },
    { "text": "Macao", "value": "MO", "country-code": '' },
    { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK", "country-code": '' },
    { "text": "Madagascar", "value": "MG", "country-code": '' },
    { "text": "Malawi", "value": "MW", "country-code": '' },
    { "text": "Malaysia", "value": "MY", "country-code": '' },
    { "text": "Maldives", "value": "MV", "country-code": '' },
    { "text": "Mali", "value": "ML", "country-code": '' },
    { "text": "Malta", "value": "MT", "country-code": '' },
    { "text": "Marshall Islands", "value": "MH", "country-code": '' },
    { "text": "Martinique", "value": "MQ", "country-code": '' },
    { "text": "Mauritania", "value": "MR", "country-code": '' },
    { "text": "Mauritius", "value": "MU", "country-code": '' },
    { "text": "Mayotte", "value": "YT", "country-code": '' },
    { "text": "Mexico", "value": "MX", "country-code": '' },
    { "text": "Micronesia, Federated States of", "value": "FM", "country-code": '' },
    { "text": "Moldova, Republic of", "value": "MD", "country-code": '' },
    { "text": "Monaco", "value": "MC", "country-code": '' },
    { "text": "Mongolia", "value": "MN", "country-code": '' },
    { "text": "Montserrat", "value": "MS", "country-code": '' },
    { "text": "Morocco", "value": "MA", "country-code": '' },
    { "text": "Mozambique", "value": "MZ", "country-code": '' },
    { "text": "Myanmar", "value": "MM", "country-code": '' },
    { "text": "Namibia", "value": "NA", "country-code": '' },
    { "text": "Nauru", "value": "NR", "country-code": '' },
    { "text": "Nepal", "value": "NP", "country-code": '' },
    { "text": "Netherlands", "value": "NL", "country-code": '' },
    { "text": "Netherlands Antilles", "value": "AN", "country-code": '' },
    { "text": "New Caledonia", "value": "NC", "country-code": '' },
    { "text": "New Zealand", "value": "NZ", "country-code": '' },
    { "text": "Nicaragua", "value": "NI", "country-code": '' },
    { "text": "Niger", "value": "NE", "country-code": '' },
    { "text": "Nigeria", "value": "NG", "country-code": '' },
    { "text": "Niue", "value": "NU", "country-code": '' },
    { "text": "Norfolk Island", "value": "NF", "country-code": '' },
    { "text": "Northern Mariana Islands", "value": "MP", "country-code": '' },
    { "text": "Norway", "value": "NO", "country-code": '' },
    { "text": "Oman", "value": "OM", "country-code": '' },
    { "text": "Pakistan", "value": "PK", "country-code": '' },
    { "text": "Palau", "value": "PW", "country-code": '' },
    { "text": "Palestinian Territory, Occupied", "value": "PS", "country-code": '' },
    { "text": "Panama", "value": "PA", "country-code": '' },
    { "text": "Papua New Guinea", "value": "PG", "country-code": '' },
    { "text": "Paraguay", "value": "PY", "country-code": '' },
    { "text": "Peru", "value": "PE", "country-code": '' },
    { "text": "Philippines", "value": "PH", "country-code": '' },
    { "text": "Pitcairn", "value": "PN", "country-code": '' },
    { "text": "Poland", "value": "PL", "country-code": '' },
    { "text": "Portugal", "value": "PT", "country-code": '' },
    { "text": "Puerto Rico", "value": "PR", "country-code": '' },
    { "text": "Qatar", "value": "QA", "country-code": '' },
    { "text": "Reunion", "value": "RE", "country-code": '' },
    { "text": "Romania", "value": "RO", "country-code": '' },
    { "text": "Russian Federation", "value": "RU", "country-code": '' },
    { "text": "RWANDA", "value": "RW", "country-code": '' },
    { "text": "Saint Helena", "value": "SH", "country-code": '' },
    { "text": "Saint Kitts and Nevis", "value": "KN", "country-code": '' },
    { "text": "Saint Lucia", "value": "LC", "country-code": '' },
    { "text": "Saint Pierre and Miquelon", "value": "PM", "country-code": '' },
    { "text": "Saint Vincent and the Grenadines", "value": "VC", "country-code": '' },
    { "text": "Samoa", "value": "WS", "country-code": '' },
    { "text": "San Marino", "value": "SM", "country-code": '' },
    { "text": "Sao Tome and Principe", "value": "ST", "country-code": '' },
    { "text": "Saudi Arabia", "value": "SA", "country-code": '' },
    { "text": "Senegal", "value": "SN", "country-code": '' },
    { "text": "Serbia and Montenegro", "value": "CS", "country-code": '' },
    { "text": "Seychelles", "value": "SC", "country-code": '' },
    { "text": "Sierra Leone", "value": "SL", "country-code": '' },
    { "text": "Singapore", "value": "SG", "country-code": '' },
    { "text": "Slovakia", "value": "SK", "country-code": '' },
    { "text": "Slovenia", "value": "SI", "country-code": '' },
    { "text": "Solomon Islands", "value": "SB", "country-code": '' },
    { "text": "Somalia", "value": "SO", "country-code": '' },
    { "text": "South Africa", "value": "ZA", "country-code": '' },
    { "text": "South Georgia and the South Sandwich Islands", "value": "GS", "country-code": '' },
    { "text": "Spain", "value": "ES", "country-code": '' },
    { "text": "Sri Lanka", "value": "LK", "country-code": '' },
    { "text": "Sudan", "value": "SD", "country-code": '' },
    { "text": "Suriname", "value": "SR", "country-code": '' },
    { "text": "Svalbard and Jan Mayen", "value": "SJ", "country-code": '' },
    { "text": "Swaziland", "value": "SZ", "country-code": '' },
    { "text": "Sweden", "value": "SE", "country-code": '' },
    { "text": "Switzerland", "value": "CH", "country-code": '' },
    { "text": "Syrian Arab Republic", "value": "SY", "country-code": '' },
    { "text": "Taiwan, Province of China", "value": "TW", "country-code": '' },
    { "text": "Tajikistan", "value": "TJ", "country-code": '' },
    { "text": "Tanzania, United Republic of", "value": "TZ", "country-code": '' },
    { "text": "Thailand", "value": "TH", "country-code": '' },
    { "text": "Timor-Leste", "value": "TL", "country-code": '' },
    { "text": "Togo", "value": "TG", "country-code": '' },
    { "text": "Tokelau", "value": "TK", "country-code": '' },
    { "text": "Tonga", "value": "TO", "country-code": '' },
    { "text": "Trinidad and Tobago", "value": "TT", "country-code": '' },
    { "text": "Tunisia", "value": "TN", "country-code": '' },
    { "text": "Turkey", "value": "TR", "country-code": '' },
    { "text": "Turkmenistan", "value": "TM", "country-code": '' },
    { "text": "Turks and Caicos Islands", "value": "TC", "country-code": '' },
    { "text": "Tuvalu", "value": "TV", "country-code": '' },
    { "text": "Uganda", "value": "UG", "country-code": '' },
    { "text": "Ukraine", "value": "UA", "country-code": '' },
    { "text": "United Arab Emirates", "value": "AE", "country-code": '' },
    { "text": "United Kingdom", "value": "GB", "country-code": '' },
    { "text": "United States", "value": "US", synonym: ['USA','United States of America'] },
    { "text": "United States Minor Outlying Islands", "value": "UM", "country-code": '' },
    { "text": "Uruguay", "value": "UY", "country-code": '' },
    { "text": "Uzbekistan", "value": "UZ", "country-code": '' },
    { "text": "Vanuatu", "value": "VU", "country-code": '' },
    { "text": "Venezuela", "value": "VE", "country-code": '' },
    { "text": "Viet Nam", "value": "VN", "country-code": '' },
    { "text": "Virgin Islands, British", "value": "VG", "country-code": '' },
    { "text": "Virgin Islands, U.S.", "value": "VI", "country-code": '' },
    { "text": "Wallis and Futuna", "value": "WF", "country-code": '' },
    { "text": "Western Sahara", "value": "EH", "country-code": '' },
    { "text": "Yemen", "value": "YE", "country-code": '' },
    { "text": "Zambia", "value": "ZM", "country-code": '' },
    { "text": "Zimbabwe", "value": "ZW" }
];