import { ref, reactive, nextTick } from "vue";
import axios from 'axios';
import router from "@/routes/routes.js";
import { loadStripe } from '@stripe/stripe-js';
import { has, filter, get } from 'lodash';
import store from '@/store';

// Role ENUM value
const roles = {
    1: 'Administrator',
    2: 'Editor',
    3: 'Contributor',
    4: 'Viewer'
};
export default function useSubscription() {
    const loading = ref(false);
    const stripe = ref({});
    const cardElement = ref({});

    const form = reactive({
        name: 'customer one',
        streetName: '',
        postalCode: '',
        country: '',
        countryCode: '',
        suite: '',
        city: '',
        state: '',
        email: ''
    })

    const processPayment = async (planId, workspace) => {
        loading.value = true;
        // const workspace = store.getters['workspace/activeWorkspaceObj'];
        const { paymentMethod, error } = await stripe.value.createPaymentMethod('card', cardElement.value, {
            billing_details: {
                name: form.name,
                email: form.email,
                address: {
                   line1: `${form.streetName} ${form.suite}`,
                    city: form.city,
                    state: form.state,
                    country: form.countryCode,
                    postal_code: form.postalCode
                }
            }
        });
        if(error) {
            console.log(error, 'Stripe error');
            loading.value = false;
        }else {
            form.payment_method_id = paymentMethod.id;
            form.plan_id = planId,
            form.workspace_id = workspace.id
            form.quantity = getEditors(workspace).length
            axios.post('profile/plan/purchase', form)
            .then(() => {
                router.push({name: 'subscription.success'})
            })
            .catch(error => {
                console.error(error)
            }).finally(() => {
                loading.value = false;
                store.dispatch("workspace/fetchWorkspaces")
            });
       }
    }

    const loadCardElement = async () => {
        stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
        const elements = stripe.value.elements();
        cardElement.value = elements.create('card', {
            hidePostalCode: true,
            classes: {
                base: 'border border-secondary-five rounded py-2 px-3 w-full fs-12'
            }
        });
    }
    const getEditors = (obj) => {
        if(!has(obj, 'responsibles')) {
            return [];
        }
        return filter(obj.responsibles, item => ['Editor', 'Administrator'].includes(roles[item.role]));
    }

    const getSubscriptions = async (workspaceId) => {
        let { data } = await axios.get(`profile/subscriptions/${workspaceId}`).then(data => data.data);
        return data;
    }

    const getTotalPrice = (obj) => {
        if(!has(obj, 'plan')) {
            return 0;
        }
        let price = get(obj, 'plan.price', 0) || '$0';
        price = Number(price.replace('$', ''));
        return `$ ${price * obj.quantity}.00`
    }
    return {
        processPayment,
        loadCardElement,
        getSubscriptions,
        getTotalPrice,
        getEditors,
        loading,
        cardElement,
        form
    }
}
