<template>
    <div class="relative border border-secondary-five rounded py-1 px-1 bg-white flex items-center">
        <input
            @focus="showItems = true"
            @blur="showItems = false"
            class="py-1 px-2 w-full fs-12"
            placeholder="State"
            type="text"
            :value="value"
            @keyup="onSelectData"
        >
        <ChevronSolidIcon class="w-3 h-2 text-secondary-one mr-1 flex-shrink-0" />

        <transition name="slide-fade">
            <div v-if="showItems" class="dropdown-area absolute top-9 left-0 bg-white overflow-y-auto shadow-one rounded z-50">
                <div
                    class="border-b border-secondary-four py-1 px-1 hover:bg-secondary-four cursor-pointer"
                    v-for="(state, index) in localStates" :key="index"
                    @mousedown="onSelect(state)"
                >
                    <span class="fs-12 px-2"> {{ state.name }} </span>
                </div>
                <div v-if="localStates.length == 0">
                    <p class="text-center fs-12 py-2">Query not matched</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
    import { ref, watchEffect } from 'vue';
    const props = defineProps({
        value: String,
        states: Array
    });
    const emit = defineEmits(['input']);
    const showItems = ref(false);
    const localStates = ref([]);

    watchEffect(() => {
        localStates.value = props.states;
    })
    const onSelectData = (e) => {
        emit('input', e.target.value);
        if(e.target.value.length > 0) {
            localStates.value = props.states.filter(state => state.name.toLowerCase().includes(e.target.value.toLowerCase()));
        }
    }
    const onSelect = ({name}) => {
        emit('input', name);
    }
</script>

<style scoped>
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
    {
        transform: translateY(10px);
        opacity: 0;
    }
    .dropdown-area {
        max-height: 130px;
        left: -1px;
        width: calc(100% + 2px);
    }
</style>