<template>
    <div class="summary-area border border-secondary-five rounded i-p-30">
        <p class="fs-16 fw-600 text-primary-one">{{ pageTitle }}</p>
        <div class="flex items-center justify-between mt-6">
            {{ localPlan.title }}
        </div>
        <div class="summary-details space-y-4 border-t border-b border-secondary-five mt-4 my-4 py-4">
            <div
                v-for="(feature) in localPlan.features"
                :key="feature.id"
                class="flex items-center justify-between"
            >
                <span :class="keyStyle">{{ feature.name }}</span>
                <span :class="valueStyle">{{ feature.max_limit }}</span>
            </div>
        </div>
        <div class="flex items-center justify-between">
            <span class="fs-14 fw-400 text-primary-one capitalize"> Subtotal </span>
            <span class="fs-14 fw-400 text-primary-one">{{ localPlan.price }} </span>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { isEmpty } from 'lodash'
    import axios from 'axios';

    const props = defineProps({
        pageTitle: {
            type: String,
            default: 'Plan Summary'
        },
        plan_id: [Number, String],
        plan: Object
    });
    const localPlan = ref(props.plan);
    onMounted(async () => {
        if(isEmpty(props.plan)) {
            let { data } = await axios.get(`profile/plans/${props.plan_id}`).then(data => data.data);
            localPlan.value = data;
        }
    });

const keyStyle = 'capitalize fw-400 fs-12 color-secondary-six'
const valueStyle = 'capitalize fw-500 fs-12 color-primary-one'
</script>


<style scoped>
.summary-area {
    width: 300px;
    height: 440px;
}
</style>
