<template>
    <div class="relative border border-secondary-five rounded py-1 px-1 bg-white flex items-center">
        <img :src="flag" class="pl-1 w-8 flex-shrink-0" alt="">
        <input
            @focus="showItems = true"
            @blur="showItems = false"
            class="py-1 px-2 w-full fs-12"
            placeholder="Select country"
            type="text"
            :value="value"
            @keyup="onSelectData"
        >
        <ChevronSolidIcon class="w-3 h-2 text-secondary-one mr-1 flex-shrink-0" />

        <transition name="slide-fade">
            <div v-if="showItems" class="dropdown-area absolute top-9 left-0 z-50 bg-white overflow-y-auto shadow-one rounded">
                <div
                    class="flex items-center gap-3 border-b border-secondary-four py-1 px-1 hover:bg-secondary-four cursor-pointer"
                    v-for="(country, index) in localCountries" :key="index"
                    @mousedown="onSelect(country)"
                >
                    <img :src="`/images/svg/${country.value.toLowerCase()}.svg`" class="pl-1 w-8 flex-shrink-0">
                    <span class="fs-12"> {{ country.text }} </span>
                </div>
                <div v-if="localCountries.length == 0">
                    <p class="text-center fs-12 py-2">Query not matched</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { countries } from '@/mock/country_flags';
    const props = defineProps({
        value: String
    });
    const emit = defineEmits(['input', 'change']);
    const showItems = ref(false);
    const localCountries = ref(countries);
    const localCountry = ref({
        value: null,
        text: null
    });
    const flag = computed(() => {
        if(!props.value || localCountries.value.length == 0) {
            return `/images/icons/subscription/flag_placeholder.png`
        }
        if(typeof localCountry.value.value == 'string') {
            return  `/images/svg/${localCountry.value.value.toLowerCase()}.svg`;
        }
        return `/images/svg/${localCountries.value[0].value.toLowerCase()}.svg`;
    });
    
    const onSelectData = (e) => {
        emit('input', e.target.value);
        if(e.target.value.length > 0) {
            localCountries.value = countries.filter(country => country.text.toLowerCase().includes(e.target.value.toLowerCase()));
        }
    }
    const onSelect = ({value, text}) => {
        localCountry.value.value = value;
        emit('input', text);
        emit('change', value);
    }
</script>

<style scoped>
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
    {
        transform: translateY(10px);
        opacity: 0;
    }
    .dropdown-area {
        max-height: 137px;
        left: -1px;
        width: calc(100% + 2px);
    }
</style>