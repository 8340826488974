var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (['Contact us'].includes(_vm.data.name) )?_c('a',{attrs:{"href":"mailto:ale@instrat360.com"}},[_c(_setup.PlanContent,{attrs:{"data":_vm.data}})],1):_c('router-link',{attrs:{"to":{
        name: 'subscription.payment',
        params: {
            plan_id: _setup.props.data.id,
            plan: _setup.props.data,
            workspace_id: _vm.$route.params.workspace_id
        }
    }}},[_c(_setup.PlanContent,{attrs:{"data":_vm.data}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }