<template>
  <div class="summary flex space-x-5 flex-wrap  justify-center">
    <div style="width: 610px" class="border border-secondary-five rounded i-p-30">
      <p class="fs-16 fw-600 text-primary-one">{{ pageTitle }}</p>
      <table class="mt-4">
        <tr 
          v-for="(value, name) in summary.details"
          :key="name"
        >
          <td :class="keyStyle">{{ name }}:</td>
          <td :class="valueStyle">{{ value }}</td>
        </tr>
      </table>
      <div class="flex items-center mt-2">
        <input style="visibility: visible" id="save-payment" type="checkbox" class="border rounded-sm border-secondary-five">
        <label for="save-payment" class="ml-2 fw-400 fs-12 color-secondary-six mt-0.5">Sve this card</label>
      </div>

      <button class="text-primary-one fs-12 w-40 h-10 border border-primary-four rounded mt-6">Make Payment</button>
    </div>
    <PaymentSummary :plan="plan" :plan_id="plan_id" />
  </div>
</template>

<script setup>
    import PaymentSummary from '@/components/workspace/subscription/PaymentSummary';
    import { ref, onMounted } from 'vue';
    const props = defineProps({
        pageTitle: {
            type: String,
            default: 'Plan Information'
        },
        currency: {
            type: String,
            default: '$'
        },
        plan_id: [Number, String],
        plan: {
            type: Object,
            default: () => Object(null)
        }
    });
const summary = {
    details: {
        'card number': 14,
        'Email address': 15,
        'street': 'Unlimited',
        'Apt./suite': 'Unlimited',
        'country': 'Unlimited',
        'city': 'Unlimited'
    },
}

const keyStyle = 'capitalize fw-400 fs-12 color-secondary-six pr-10 py-2'
const valueStyle = 'capitalize fw-500 fs-12 color-primary-one'

</script>