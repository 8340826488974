<template>
    <div
        :class="[
            ['Free'].includes(data.name) ? 'pointer-events-none' : '',
            workspace.subscription?.plan_id == data.id ? 'border-primary-four shadow-one' : 'border-secondary-five'
        ]"
        class="card border cursor-pointer rounded pl-8 py-7 pr-7 hover:border-primary-four hover:shadow-one"
        >
            <img :src="`/images/icons/subscription/${data.icon}`" alt="">
            <h2 class="fs-14 font-semibold tracking-wide mt-6">{{ data.title }}</h2>
            <p class="fs-14 font-normal mt-2">{{ data.subtitle }}</p>
            <h1 v-if="data.name" class="fs-20 font-semibold text-primary-one mt-4">{{ data.name }}</h1>
            <h1 v-if="data.price" class="fs-20 font-semibold text-primary-one mt-4">{{ data.price }}/<small class="font-normal fs-13">Per editor </small> </h1>
            <p class="fs-14 font-normal text-secondary-six mt-1">{{ data.type }}</p>
            <ul class="mt-4 space-y-4">
                <li v-for="(item, index) in data.features" :key="index" class="flex gap-x-2">
                    <img src="/images/icons/subscription/check.svg" alt="">
                    <p class="fs-11 font-normal">{{ item.max_limit }} {{ item.name }}</p>
                </li>
            </ul>
       </div>
</template>

<script setup>
    import { computed } from 'vue';
    import store from '@/store';
    defineProps({
        data: Object
    });
    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
</script>