import axios  from 'axios';
export default function useDirectory() {
    const config = {
        headers: {
            "X-CSCAPI-KEY": 'SFBtSTdIT2pqM0x1b3N5N1FGc1Y3aXBuYzdtOWlhWUd4UklQZDd5bQ=='
        }
    }
    const baseUrl = `https://api.countrystatecity.in/v1`;
    const getStateByCountryCode = async (code) => {
        if(!code) {
            return;
        }
        let url = `${baseUrl}/countries/${code}/states`;
        return await axios.get(url, config).then(data => data.data);
    }

    const getCountryDetails = async (code) => {
        if(!code) {
            return;
        }
        let url = `${baseUrl}/countries/${code}`;
        return await axios.get(url, config).then(data => data.data);
    }

    return {
        getCountryDetails,
        getStateByCountryCode
    }
}